.comments
    margin: 20px 0 30px

    + .comments
        margin-top: -20px

    + .buttongroup
        margin-top: -20px

.comment
    position: relative
    background-color: #fff
    padding: 10px
    border-radius: 25px
    margin-bottom: 5px

.comments-history
    .comment
        background-color: #f8f8f8

.comment-name
    @extend %bold-font

.comment-date
    position: absolute
    right: 30px
    top: 15px
    color: silver
    font-size: 14px

.comment-text
    padding: 0 0 0 50px
    margin: 0

.comment-header
    display: flex
    align-items: center
    grid-gap: 0 15px

.comment-avatar
    font-size: 14px
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    border-radius: 50%
    background-color: #000
    color: #fff

.comment-avatar-contura
    background-color: $color-brand-orange
    background-image: url(../../images/contura-logo-neg.svg)
    background-repeat: no-repeat
    background-position: center center
    background-size: 30px 30px

@media screen and (max-width: 600px)
    .comment-date
        position: relative
        right: unset
        top: unset
