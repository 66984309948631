form
    margin: 70px auto 0
    max-width: 700px

.field-text,
.field-password,
.field-search,
.field-file,
.field-textarea,
.field-select-input,
.field-has-button .field
    background-color: #fff
    padding: 1em
    font-size: 1rem
    border-radius: 25px
    display: block
    width: 100%

.field,
.field-file-wrap
    margin-bottom: 30px

//.field-password
    font-size: 2em
    padding: 0.19em 0.6em

.field-item
    width: 100%
    text-align: left

.field-textarea
    resize: vertical
    min-height: 5em

.button-submit
    display: block
    margin: 70px auto 0

label
    display: block
    margin: 0 0 10px 1em
    cursor: pointer

.label-error
    background-color: red
    color: #fff
    display: inline-block
    margin: 5px 0 20px 20px
    padding: 5px 10px
    font-size: 12px

.field-label
    font-weight: 600
    margin-top: 2em

    &--small
        font-size: 12px
        color: #727171

.field-description
    opacity: 0.8
    font-size: 16px
    padding: 0 18px
    margin-top: -20px
    margin-bottom: 30px

.field-error,
.field-select-error .field-select-input,
.field-wrap-error .field
    box-shadow: 0 0 0 2px red inset
    margin-bottom: 0

.field-wrap-error
    margin-bottom: 0

// Checkboxes
.field-checkbox
    display: none

    + label
        border: 1px solid $color-grey-light
        font-size: 0.8rem
        padding: 3px 10px
        border-radius: 4px

    &:checked
        + label
            color: #fff
            background-color: $color-grey-dark
            border-color: #000

// Traditional styled checkbox
.field-item-checkbox
    margin-bottom: 20px

    label
        cursor: pointer
        position: relative
        padding-left: 40px
        display: flex
        align-items: center
        min-height: 30px
        margin-left: 0

        &:before,
        &:after
            content: ' '
            position: absolute
            height: 30px
            width: 30px
            transition: border 250ms, background-color 250ms, transform 250ms

        &:before
            transform: scale(0)

        &:after
            background-color: #fff
            border: 2px solid $color-font-body
            top: 0
            left: 0
            z-index: 1
            box-shadow: none
            border-radius: 4px

    input
        display: none

        &:checked
            + label
                &:before
                    top: 6px
                    left: 5px
                    width: 10px
                    height: 15px
                    border-top: 2px solid transparent
                    border-left: 2px solid transparent
                    border-right: 2px solid #fff
                    border-bottom: 2px solid #fff
                    transform: rotateZ(37deg)
                    transform-origin: 100% 100%

                    z-index: 1

                &:after
                    top: 0
                    border: 1px solid $color-grey-dark
                    background-color: $color-grey-dark
                    z-index: 0
                    box-shadow: none

// Search
.button-search
    width: 70px
    position: absolute
    top: 0
    right: 0
    padding: 7px 20px 3px 10px

    path
        fill: #a4a5a2

.field-search
    display: flex
    justify-content: space-between
    position: relative

.field-search-input
    font-size: 1rem
    margin-bottom: 0
    width: calc(100% - 40px)

    &::-webkit-search-cancel-button // sass-lint:disable-line no-vendor-prefixes
        display: none

    &:focus
        ~ .button-search
            path
                fill: $color-font-body

.field-has-button
    display: flex
    gap: 20px // sass-lint:disable-line no-misspelled-properties

    button
        margin: 0

// Select
.field-select-input
    background-image: url(../../images/icons/angle-down-solid.svg)
    background-repeat: no-repeat
    background-size: 20px
    background-position: calc(100% - 20px) 50%
    padding-right: 50px

// Select box (label % select inline)
.field-select-box
    display: flex
    justify-content: space-between
    margin-bottom: $margin-small
    position: relative

    &:after
        content: ''
        position: absolute
        top: 50%
        right: 14px
        width: 20px
        height: 20px
        margin-top: -6px
        pointer-events: none
        +css-arrow(bottom, 8px, 2px, #000)

    label, select
        background-color: #fff
        margin: 0
        padding: 7px 13px 6px
        font-size: 16px

    select
        width: 80px
        margin-left: 2px
        border-top-right-radius: 16px
        border-bottom-right-radius: 16px

    label
        width: calc(100% - (80px + 2px))
        border-top-left-radius: 16px
        border-bottom-left-radius: 16px

// Fileupload
.field-file
    position: relative
    display: grid
    grid-template-columns: auto 1fr

    + .buttongroup
        margin-top: 10px

.field-file-icon
    width: 54px
    height: 54px
    position: absolute
    top: 0
    right: 10px
    display: flex
    justify-content: center
    align-items: center

    svg
        width: 20px
        transition: all 0.2s ease

    &:hover
        svg
            fill: $color-brand-orange

.field-file-icon-clear
    cursor: pointer
    z-index: 3

.field-file-buttons
    text-align: right

.field-file-button
    position: relative
    overflow: hidden
    display: inline-block
    z-index: 2
    font-family: $font-family-body
    max-height: 42px

    &:before
        @extend %button-secondary
        margin: 0
        content: 'Select file'
        display: inline-block

    input[type='file']
        opacity: 0
        position: absolute
        top: 0
        left: 0
        bottom: 0
        font-size: 100px
        padding: 0
        margin: 0
        cursor: pointer

html:lang(sv) .field-file-button:before
    content: 'Välj fil'

.field-file-button-hidden
    opacity: 0
    width: 0
    height: 0

.field-file-placeholder
    color: $color-font-body

.field-file-placeholder
    padding-right: 60px

[readonly]
    opacity: 0.5

[disabled]
    opacity: 0.2
    color: silver

[type='search']
    &::-webkit-search-cancel-button, // sass-lint:disable-line no-vendor-prefixes
    &::-webkit-search-decoration // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none // sass-lint:disable-line no-vendor-prefixes
        appearance: none
